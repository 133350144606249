import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  currentLang: string;
  languageList = {
    hu: {
      label: 'Magyar',
    },
    en: {
      label: 'English',
    },
  };

  private readonly destroy = new Subject<void>();

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.translate.onLangChange
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: change => {
          this.currentLang = change.lang;
        }
      });

    this.currentLang = this.translate.currentLang;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }
}
