<div class="wrapper">
  <span class="header left">{{ "CLAIMS_LIST.HEADER.INDEX" | translate }}</span>
  <span class="header right">{{ "CLAIMS_LIST.HEADER.DUE_DATE" | translate }}</span>
  <span class="header right">{{ "CLAIMS_LIST.HEADER.AMOUNT" | translate }}</span>
  <mat-divider></mat-divider>
  <ng-container *ngFor="let claim of caseData.claims; let i = index">
    <span class="data left">
      <span>{{ "CLAIMS_LIST.INDEX" | translate:{ index: i + 1 } }}</span>
      <a
        *ngIf="claim.file"
        [href]="claim.file.storage_url"
        target="_blank"
        style="margin-left: 15px;"
      >
        {{ claim.file.file_name }}
      </a>
    </span>
    <span class="data right">{{
      claim.expired_at | date : "yyyy.MM.dd."
    }}</span>
    <span class="data right"
      >{{ claim.remaining_amount | number : "1.0" : "hu" }}
      {{ caseData.currency }}</span
    >
  </ng-container>
</div>
