<!-- <app-language-selector></app-language-selector> -->

<div class="wrapper flex column vcenter">
  <div class="img-box flex vcenter hcenter">
    <img src="assets/doc.svg" height="71.43px" />
  </div>
  <div class="title flex vcenter">
    {{ "CASE_HARD_DIALOG.TITLE" | translate }}</div>
  <div class="flex grow1 vcenter">
    <div class="description">
      {{ "CASE_HARD_DIALOG.DESCRIPTION" | translate }}
    </div>
  </div>
  <a href="https://payee.tech">
    <button mat-button class="confirm-button">
      {{ "CASE_HARD_DIALOG.CONFIRM" | translate }}
    </button>
  </a>
</div>
