<div class="wrapper flex vcenter">
  <mat-icon>language</mat-icon>
  <button mat-flat-button [matMenuTriggerFor]="menu">
    {{ languageList[currentLang]?.label }}
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <a
      mat-menu-item
      *ngFor="let lang of languageList | keyvalue"
      (click)="changeLanguage(lang.key)"
    >
      {{ lang.value.label }}
    </a>
  </mat-menu>
</div>
