<div class="wrapper flex column vcenter hcenter">
  <div *ngIf="!caseData.is_payment_plan">{{ 'COUNTDOWN.DEADLINE_HARD' | translate }}</div>
  <div *ngIf="caseData.is_payment_plan">{{ 'COUNTDOWN.DEADLINE_INSTALLMENT' | translate }}</div>
  <div class="flex counter hcenter">
    <div>
      <div class="digit">{{ day }}</div>
      <div class="unit">{{ 'COUNTDOWN.DAY' | translate }}</div>
    </div>
    <div class="separator">&nbsp;:&nbsp;</div>
    <div>
      <div class="digit">{{ hour }}</div>
      <div class="unit">{{ 'COUNTDOWN.HOUR' | translate }}</div>
    </div>
    <div class="separator">&nbsp;:&nbsp;</div>
    <div>
      <div class="digit">{{ minute }}</div>
      <div class="unit">{{ 'COUNTDOWN.MINUTE' | translate }}</div>
    </div>
    <div class="separator">&nbsp;:&nbsp;</div>
    <div>
      <div class="digit">{{ second }}</div>
      <div class="unit">{{ 'COUNTDOWN.SECOND' | translate }}</div>
    </div>
  </div>
  <ng-container *ngIf="!caseData.is_payment_plan">
    <div class="hard-collection-at">
      {{ 'COUNTDOWN.DEADLINE_AT' | translate }}
      {{ caseData.hard_collection_at | date : "yyyy.MM.dd. HH:mm" }}
    </div>
    <div class="warning-message">
      {{ 'COUNTDOWN.WARNING_HARD' | translate }}
    </div>
  </ng-container>
  <ng-container *ngIf="caseData.is_payment_plan">
    <div class="hard-collection-at">
      {{ 'COUNTDOWN.DEADLINE_AT' | translate }} {{ firstPaymentPlanDueDate | date : "yyyy.MM.dd. HH:mm" }}
    </div>
    <div class="warning-message">
      {{ 'COUNTDOWN.WARNING_INSTALLMENT' | translate }}
    </div>
  </ng-container>
</div>
<div
  class="hard-col-amount flex column hcenter"
  *ngIf="showHardAmount"
>
  <div class="title">
    {{ 'COUNTDOWN.HARD_AMOUNT.TITLE' | translate }}
  </div>
  <div class="amount">
    {{ caseData.amount_after_legal_fees | number : "1.0" : "hu" }}
    {{ caseData.currency }}
  </div>
</div>
