<div class="wrapper">
  <ng-container *ngIf="!ppStarted">
    <div class="img-box flex vcenter hcenter">
      <img src="assets/percentage.svg" width="57.14px" height="57.14px" />
    </div>
    <div class="title">{{ "PAYMENT_PLAN_START_DIALOG.TITLE" | translate }}</div>
    <div class="description">
      {{ "PAYMENT_PLAN_START_DIALOG.DESCRIPTION" | translate }}
    </div>
    <app-custom-input-field appearance="outline">
      <button
        custom-input
        mat-button
        [matMenuTriggerFor]="paymentPlansMenu"
        class="pp-menu-button"
      >
        {{
          "PAYMENT_PLAN_START_DIALOG.COUNT_SELECTOR.TITLE"
            | translate : { count: selectedPayment }
        }}
      </button>
      <mat-menu #paymentPlansMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let count of caseData.payment_plan_options"
          (click)="selectPaymentPlan(count)"
        >
          {{
            "PAYMENT_PLAN_START_DIALOG.COUNT_SELECTOR.ITEM"
              | translate : { count }
          }}
        </button>
      </mat-menu>
      <span custom-label class="custom-label-top">{{
        "PAYMENT_PLAN_START_DIALOG.COMMUNICATION_LANGUAGE" | translate
      }}</span>
      <div custom-suffix style="pointer-events: none">
        <mat-icon>arrow_drop_down</mat-icon>
      </div>
    </app-custom-input-field>
    <div class="payment-plans-grid">
      <ng-container *ngFor="let pp of selectedPaymentPlans; let i = index">
        <div class="column-1">
          {{
            "PAYMENT_PLAN_START_DIALOG.INSTALLMENT.INDEX"
              | translate : { index: i + 1, due_date: pp.dueDate }
          }}
        </div>
        <div class="column-2">
          {{ pp.paymentAmount }} {{ caseData.currency }}
        </div>
      </ng-container>
    </div>
    <form class="flex column checkbox-box">
      <app-custom-checkbox
        [checked]="pp.value"
        (onclick)="pp.setValue(!pp.value)"
      >
        <span class="checkbox-text">
          <a href="https://www.payee.tech/legal/privacy-policy" target="_blank">
            {{ "PAYMENT_PLAN_START_DIALOG.ACCEPT_PRIVACY" | translate }}
          </a>
        </span>
      </app-custom-checkbox>
      <app-custom-checkbox
        [checked]="aszf.value"
        (onclick)="aszf.setValue(!aszf.value)"
      >
        <span class="checkbox-text">
          <a
            href="https://www.payee.tech/legal/terms-and-conditions"
            target="_blank"
          >
            {{ "PAYMENT_PLAN_START_DIALOG.ACCEPT_TOS" | translate }}
          </a>
        </span>
      </app-custom-checkbox>

      <div class="flex column buttons-box">
        <button
          mat-button
          class="accept-button"
          (click)="startPaymentPlan()"
          [disabled]="agreementForm.invalid || loading"
        >
          <span *ngIf="!loading">{{
            "PAYMENT_PLAN_START_DIALOG.ACTIVATE_BUTTON" | translate
          }}</span>
          <div class="bw-theme flex hcenter" *ngIf="loading">
            <mat-progress-spinner
              color="accent"
              mode="indeterminate"
              diameter="30"
            >
            </mat-progress-spinner>
          </div>
        </button>
        <button mat-button class="cancel-button" mat-dialog-close>
          {{ "PAYMENT_PLAN_START_DIALOG.CANCEL_BUTTON" | translate }}
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="ppStarted">
    <div class="title">
      {{ "PAYMENT_PLAN_START_DIALOG.STARTED.TITLE" | translate }}
    </div>
    <div class="description">
      {{ "PAYMENT_PLAN_START_DIALOG.STARTED.DESCRIPTION" | translate }}
    </div>
    <div class="buttons-box">
      <button
        mat-button
        class="accept-button"
        mat-dialog-close
        style="margin-top: 17px"
      >
        {{ "PAYMENT_PLAN_START_DIALOG.STARTED.CONFIRM" | translate }}
      </button>
    </div>
  </ng-container>
</div>
