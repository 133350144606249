<div class="wrapper flex">
  <div class="bg-outter-box grow1">
    <div class="bg-inner-box">
      <img src="assets/payee-logo-white.svg" width="100px" />
    </div>
  </div>
  <div class="content grow1">
    <!-- <app-language-selector></app-language-selector> -->
    <div class="content-box flex column">
      <img class="check" src="assets/check.svg" />
      <span class="title">{{ "PAYMENT_SUCCESS.TITLE" | translate }}</span>
      <span class="description">{{
        "PAYMENT_SUCCESS.DESCRIPTION" | translate
      }}  </span>
    </div>
  </div>
</div>
