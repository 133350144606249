<div class="wrapper">
  <div class="title">{{ "BANK_TRANSFER.TITLE" | translate }}</div>
  <app-custom-input-field>
    <input #iban custom-input [value]="bankAccount.account_number" readonly />
    <span custom-label class="custom-label-top">{{ "BANK_TRANSFER.ACCOUNT_NUMBER" | translate }}</span>
    <button
      mat-icon-button
      custom-suffix
      class="copy-icon"
      (click)="copyToClipboard(iban)"
    >
      <img src="assets/copy_icon.svg" width="44px" height="44px" />
    </button>
  </app-custom-input-field>
  <app-custom-input-field>
    <input
      #name
      custom-input
      [value]="bankAccount.account_holder_name"
      readonly
    />
    <span custom-label class="custom-label-top">{{ "BANK_TRANSFER.BENEFICIARY_NAME" | translate }}</span>
    <button
      mat-icon-button
      custom-suffix
      class="copy-icon"
      (click)="copyToClipboard(name)"
    >
      <img src="assets/copy_icon.svg" width="44px" height="44px" />
    </button>
  </app-custom-input-field>
  <app-custom-input-field>
    <input
      #caseReferenceId
      custom-input
      [value]="caseData.payee_case_reference_id"
      readonly
    />
    <span custom-label class="custom-label-top">{{ "BANK_TRANSFER.TRANSFER_MESSAGE" | translate }}</span>
    <button
      mat-icon-button
      custom-suffix
      class="copy-icon"
      (click)="copyToClipboard(caseReferenceId)"
    >
      <img src="assets/copy_icon.svg" width="44px" height="44px" />
    </button>
  </app-custom-input-field>
</div>
