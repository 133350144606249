import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'payment-dev-payee';

  constructor(
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.translate.addLangs(['hu', 'en']);
    this.translate.setDefaultLang('hu');

    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|hu/) ? browserLang : 'hu');
  }
}
